/**
 * Determines if we're running in production.
 *
 * @returns {boolean} Are we in production?
 */

const testSites = [process.env.REACT_APP_TEST_HOST, 'localhost', '127.0.0.1'];
// eslint-disable-next-line max-len
export const isRunningInTest = (): boolean => new RegExp(testSites.join('|')).test(window.location.hostname);
// eslint-disable-next-line max-len
export const isRunningInStage = (): boolean =>
  window.location.hostname.includes(process.env.REACT_APP_STAGE_HOST as string);

type APIGWObject = { 'us-east-1': string; 'us-west-2': string };
/**
 * Returns the API Gateway VPCE for our environment.
 *
 * @returns {APIGWObject} API Gateway VPCE (APIGWObject)
 */
export const determineAPIGatewayURL = (): APIGWObject =>
  isRunningInTest() === true
    ? determineTestUrl()
    : determineProdUrl();

export const determineTestUrl = (): APIGWObject =>
  isRunningInStage() === true
    ? ({
        'us-east-1': process.env.REACT_APP_TEST_STAGE_API_GATEWAY_VPCE_EAST || '',
        'us-west-2': process.env.REACT_APP_TEST_STAGE_API_GATEWAY_VPCE_WEST || ''
      } as APIGWObject)
    : ({
        'us-east-1': process.env.REACT_APP_TEST_API_GATEWAY_VPCE_EAST || '',
        'us-west-2': process.env.REACT_APP_TEST_API_GATEWAY_VPCE_WEST || ''
      } as APIGWObject);

export const determineProdUrl = (): APIGWObject =>
  isRunningInStage() === true
    ? ({
        'us-east-1': process.env.REACT_APP_PROD_STAGE_API_GATEWAY_VPCE || '',
        'us-west-2': process.env.REACT_APP_PROD_STAGE_API_GATEWAY_VPCE || ''
      } as APIGWObject)
    : ({
        'us-east-1': process.env.REACT_APP_PROD_API_GATEWAY_VPCE_EAST || '',
        'us-west-2': process.env.REACT_APP_PROD_API_GATEWAY_VPCE_WEST || ''
      } as APIGWObject);

/**
 * Navigates to a route within our SPA
 *
 * @param {string} path The path we want to route to, e.g. '/faq'
 * @param {boolean} push404IntoHistory Do we want to push '/404-Page-Not-Found' route into history? Default false
 */
export const navigateToRoute = (path: string, push404IntoHistory = false): void => {
  if (push404IntoHistory) {
    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, '', '/404-Page-Not-Found');
  }
  // eslint-disable-next-line no-restricted-globals
  location.replace(path);
};
