/* eslint-disable max-len */
export const NUMBER_OF_CHARACTERS_ALLOWED_FOR_REASON = 500;
export const NUMBER_OF_CHARACTERS_EXCEEDS_MAX = `Please enter ${NUMBER_OF_CHARACTERS_ALLOWED_FOR_REASON} or fewer characters.`;
export const INVALID_EMAIL = 'Please enter a valid email address.';
export const INVALID_URL = 'Please enter a valid URL.';
export const REDIRECT_ON_PAGE_NOT_FOUND_MILISECONDS = 3000;
export const DISPLAY_VIEWPORT_NAMES = {
  DESKTOP: '',
  TABLET: 'mobile',
  MOBILE: 'mobile'
};
export const API_LIST = ['Renters'].sort();
export const toastDurationInMS = 4000;
export const buttonDisableTimerInMS = 2000;
export const timeoutAPIGWEast = 3500;
export const timeoutAPIGWWest = 3500;
