import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import PartnerGatewayWrapper from '@c/PartnerGatewayWrapper';
import reportWebVitals from './reportWebVitals';
import './index.css';
import axe from '@axe-core/react';
 
if (process.env.NODE_ENV === 'development') {
  // Turn on A11Y debugging while in development mode.
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const a11y = require('react-a11y').default;
  a11y(React, ReactDOM, {
    rules: {
      'img-uses-alt': 'warn',
      'img-redundant-alt': ['warn', ['image', 'photo', 'foto', 'bild']]
    }
  });
  // Turn on AXE (a11y) logging / analysis while in development mode.
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  axe(React, ReactDOM, 1000);
}

ReactDOM.render(
  // StrictMode; see https://reactjs.org/docs/strict-mode.html
  <React.StrictMode>
    <BrowserRouter>
      <PartnerGatewayWrapper />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
