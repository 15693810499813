/* eslint-disable @typescript-eslint/no-explicit-any */
/* TODO: As this component runs through Cypress for a11y AT, we'd have to monkey around
*         with Babel / Webpack / react-app-unwired or whatever to make these aliases work
*         inside the Cypress context. I have pushed hard on this for a half-day, no luck.
*         SO, the TODO here is, "when we go to 2.0, do aliasing right & know how we package."
*/
import Toast from '../Messaging/Toast';
import { NUMBER_OF_CHARACTERS_ALLOWED_FOR_REASON, timeoutAPIGWEast, timeoutAPIGWWest, buttonDisableTimerInMS, toastDurationInMS } from '../../util/constants';
import { determineAPIGatewayURL, isRunningInTest } from '../../util/network';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { ReactElement, useEffect, useState } from 'react';
import * as Yup from 'yup';
import isEmailValidator from 'validator/lib/isEmail';
import isURL from 'validator/lib/isURL';


const API_GATEWAY_URL_SENDMAIL_ADDRESS_EAST = `${determineAPIGatewayURL()['us-east-1']}/sendmail`;
const API_GATEWAY_URL_SENDMAIL_ADDRESS_WEST = `${determineAPIGatewayURL()['us-west-2']}/sendmail`;
const API_NAME = isRunningInTest() ? 'test' : 'prod';

const clearAndClose = (): void => {
  // this.clearErrorDivs();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).oneX.Modal.hideModal() as any;
};

const displayToast = (): void => {
  (window as any).oneX.Util.animations.displayToast(
    (window as any).oneX.$('#sendmail-toast'),
    (window as any).oneX.$('#request-access-button'),
    toastDurationInMS
  ) as any;
};

interface formSchema {
  name: string,
  email: string,
  companyName: string,
  companyUrl: string,
  reason: string,
  [key: string]: any
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Your name must be longer than one character')
    .max(50, 'Your name must be shorter than 50 characters')
    .required('Your name is required')
    .matches(
      /^[a-zA-Z0-9'\- ]+$/,
      'Cannot contain special characters'
    ),
  email: Yup.string()
    .email('The email you entered is invalid')
    .required('Your email address is required')
    .test('is-valid', message => `${message.path} is invalid`, value => value
      ? isEmailValidator(value)
      : new Yup.ValidationError('The email you entered is invalid')),
  companyName: Yup.string()
    .min(2, 'Your company name must be longer than one character')
    .max(50, 'Your company name must be shorter than 50 characters')
    .required('Your company name is required'),
  companyUrl: Yup.string()
    .min(2, 'Your company web site must be longer than one character')
    .max(50, 'Your company web site must be shorter than 50 characters')
    .required('Your company web site is required')
    .test('is-valid', message => `${message.path} is invalid`, value => value
      ? isURL(value)
      : new Yup.ValidationError('Your company web site is invalid')),
  reason: Yup.string()
    .min(2, 'Your use-case must be longer than one character')
    .max(500, 'Your use-case must be shorter than 500 characters')
    .required('Your use-case is required')
});

const initialValues = {
  name: '',
  email: '',
  companyName: '',
  companyUrl: '',
  reason: ''
};

/**
   *
   * @param {any} errorObj - Original Axios error object, including any sensitive header information that may exist.
   *
   * @returns {Record<string, unknown>} errorObj - Axios error object, with sensitive header information removed.
   *
   */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const handleAxiosError = (errorObj: any): Record<string, unknown> => {
  const outboundObj = errorObj;
  delete outboundObj.config.headers.Authorization;
  delete outboundObj.request.header;
  return outboundObj;
};

const handleSubmit = async (data: formSchema) => {
  clearAndClose();
  // e.preventDefault();
  // document.getElementById('submit-button')?.setAttribute('disabled', 'true');
  data.apiName=API_NAME;
  let SUCCESS = false;

  console.log(`handleSubmit() saw ${JSON.stringify(data, null, 2)}`);

  try {
    const res = await axios
      .post(API_GATEWAY_URL_SENDMAIL_ADDRESS_EAST, data, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Accept': 'application/json;charset=UTF-8'
        },
        timeout: timeoutAPIGWEast
      });
    console.log(`Success with code: ${res.status}`);
    SUCCESS = true;
  } catch (err) {
    console.log(`Error (us-east-1): ${handleAxiosError(err)}`);
    // Try to call us-west-2 APIGW
    try {
      const res = await axios
        .post(API_GATEWAY_URL_SENDMAIL_ADDRESS_WEST, data, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept': 'application/json;charset=UTF-8'
          },
          timeout: timeoutAPIGWWest
        });
      console.log(`Success (us-west-2) with code: ${res.status}`);
      return SUCCESS = true;
    } catch (err) {
      if (typeof (err as Record<string, unknown>)?.message === 'string') {
        console.log(`Error (us-west-2): ${handleAxiosError(err)}`);
      } else {
        handleAxiosError(err).response
          ? console.log(`Error (us-west-2): ${((handleAxiosError(err)).response as Record<string, unknown>)?.status}`)
          : console.log(`Error (us-west-2): ${(handleAxiosError(err).message as string)}`);
      }
      return SUCCESS = false;
    }
  }
  setTimeout(
    () => document.getElementById('submit-button')?.removeAttribute('disabled'),
    buttonDisableTimerInMS
  );
  return SUCCESS;
};

const ContactModal = (): ReactElement => {
  const [toastObject, setToastObject] = useState({message: '', iconType: ''});
  const [charactersRemaining, setCharactersRemaining] = useState(NUMBER_OF_CHARACTERS_ALLOWED_FOR_REASON);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async values => {
      const submissionSuccess = await handleSubmit(values);
      formik.resetForm();

      console.log(`submissionSuccess is ${submissionSuccess}`);

      if (submissionSuccess) {
        setToastObject({
          message: 'Successfully submitted request!',
          iconType: 'success_fill_24'
        });
      } else if (!submissionSuccess) {
        setToastObject({
          message: 'Failed to submit request. Please try again later.',
          iconType: 'alert_fill_24'
        });
      }
      displayToast();
    }
  });
  const submitting = formik?.isSubmitting;

  useEffect(() => {
    const el = document.querySelector('.-oneX-widget__err-text, [data-error]');
    (el?.parentElement ?? el)?.children[1]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    ((el?.parentElement ?? el)?.children[1] as HTMLElement)?.focus();
  }, [submitting]);

  const {message, iconType} = toastObject;

  return (
    <div>
      <div id="oneX-modal-1" className="-oneX -oneX-modal__container" data-disabled-scrim style={{ display: 'none' }}>
        <div className="-oneX-modal-content" aria-labelledby="dialogNoAction" role="dialog">
          <div id="dialogNoAction" className="-oneX-modal-heading" aria-level={1} tabIndex={-1} role="heading">
            <span id="modal-heading">
              Let&apos;s Get Started with API Access.
            </span>
          </div>
          <div className="-oneX-modal-body">
            <p>We&apos;ll need some basic information. Then, we&apos;ll contact you with more info about our APIs.</p>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label htmlFor="name" className="-oneX-textfield__floatingLabel">Your Name</label>
                <input id="name" type="text" autoComplete="given-name" aria-live="polite" aria-required="true" aria-autocomplete="inline" aria-describedby="name-error" className="-oneX-textfield--floating-input" {...formik.getFieldProps('name')} />
                {formik.touched.name && formik.errors.name ? (
                  <div id='name-error' className="-oneX-widget__err-text" aria-live="assertive" aria-invalid="true">{formik.errors.name}</div>
                ) : null}
              </div>
 
              <div className="form-group">
                <label htmlFor="email" className="-oneX-textfield__floatingLabel">Email Address</label>
                <input id="email" type="email" autoComplete="work-email" aria-live="polite" aria-required="true" aria-autocomplete="inline" aria-describedby="email-error" className="-oneX-textfield--floating-input" {...formik.getFieldProps('email')} />
                {formik.touched.email && formik.errors.email ? (
                  <div id='email-error' className="-oneX-widget__err-text" aria-live="assertive" aria-invalid="true">{formik.errors.email}</div>
                ) : null}
              </div>
 
              <div className="form-group">
                <label htmlFor="companyName" className="-oneX-textfield__floatingLabel">Company Name</label>
                <input id="companyName" type="text" autoComplete="company-name" aria-live="polite" aria-required="true" aria-autocomplete="inline" aria-describedby="companyName-error" className="-oneX-textfield--floating-input" {...formik.getFieldProps('companyName')} />
                {formik.touched.companyName && formik.errors.companyName ? (
                  <div id='companyName-error' className="-oneX-widget__err-text" aria-live="assertive" aria-invalid="true">{formik.errors.companyName}</div>
                ) : null}
              </div>
 
              <div className="form-group">
                <label htmlFor="companyUrl" className="-oneX-textfield__floatingLabel">Company URL</label>
                <input id="companyUrl" type="text" autoComplete="company-url" aria-live="polite" aria-required="true" aria-autocomplete="inline" aria-describedby="companyUrl-error" className="-oneX-textfield--floating-input" {...formik.getFieldProps('companyUrl')} />
                {formik.touched.companyUrl && formik.errors.companyUrl ? (
                  <div id='companyUrl-error' className="-oneX-widget__err-text" aria-live="assertive" aria-invalid="true">{formik.errors.companyUrl}</div>
                ) : null}
              </div>
 
              <div className="form-group">
                <label htmlFor="reason" className="-oneX-textfield__label">Reason</label>
                <textarea onKeyUp={event => setCharactersRemaining(NUMBER_OF_CHARACTERS_ALLOWED_FOR_REASON - event.currentTarget.value.length)} id="reason" rows={4} autoComplete="reason" aria-live="polite" aria-required="true" aria-autocomplete="inline" aria-describedby="reason-error" className="-oneX-textfield--conversational-input" {...formik.getFieldProps('reason')} />
                <div className="-oneX-textarea-charRemaining">{charactersRemaining} characters remaining</div>
                <div className="-oneX-textarea-sensitiveText">
                  Disclaimer: For your protection, please do not include sensitive personal information such as social
                  security number, credit/debit card number, or health/medical information.
                </div>
                {formik.touched.reason && formik.errors.reason ? (
                  <div id='reason-error' className="-oneX-widget__err-text" aria-live="assertive" aria-invalid="true">{formik.errors.reason}</div>
                ) : null}
              </div>
 
              <button
                type="submit"
                id="submit-button"
                className="-oneX-btn-primary cta-btn"
                style={{ minWidth: '200px' }}
              >
                  Submit
              </button>
            </form>
          </div>
          <button
            id="closeModalButton"
            type="button"
            className="-oneX-close-X-button"
            onClick={() => {
              formik.resetForm();
              clearAndClose();
            }}
            aria-label="Close Modal"
          >
            <span className="-oneX-icon--close" />
          </button>
        </div>
      </div>
      <Toast
        message={message}
        iconType={iconType}
      />
    </div>
  );
};

export default ContactModal;