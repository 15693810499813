// eslint-disable-next-line no-use-before-define
import React from 'react';
import createDOMPurify from 'dompurify';

const DOMPurify = createDOMPurify(window);

const isHTML = stringUnderTest => {
  const fragment = document.createRange().createContextualFragment(stringUnderTest);
  // rip out non-text nodes.
  fragment.querySelectorAll('*').forEach(el => el.parentNode.removeChild(el));
  return !(fragment.textContent || '').trim();
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const SanitizeMarkup = (inputMarkupOrText, optionalMarkupTag = 'div', optionalMarkupClassString = null) => {
  // Did someone drop HTML inside of a plain-text object? Example: "We like to be <em>emphatic</em>."
  // eslint-disable-next-line react/destructuring-assignment
  if (inputMarkupOrText.includes('</') || inputMarkupOrText.includes('/>')) {
    // eslint-disable-next-line no-param-reassign, max-len
    inputMarkupOrText = `<span ${
      optionalMarkupClassString
        ? `className="${optionalMarkupClassString}"`
        : ''
    }>${inputMarkupOrText}</span>`;
  }
  if (isHTML(inputMarkupOrText)) {
    // eslint-disable-next-line react/no-danger, max-len
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <div
        className={optionalMarkupClassString}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(inputMarkupOrText)
        }}
      />
    );
  }
  return React.createElement(optionalMarkupTag || 'div', { className: optionalMarkupClassString }, inputMarkupOrText);
};

export default SanitizeMarkup;
