import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Home from '@c/Home/Home';
import FAQ from '@c/FAQ/FAQ';
import { TemplateAPIPage } from '@c/TemplateAPIpage/TemplateAPIPage';
import NotFound from '@c/NotFound/NotFound';

export const Routes = (): JSX.Element => (
  <>
    <Switch>
      <Redirect exact from="/" to="home" />
      <Route exact path="/home" component={Home} />
      <Route exact path="/faq" component={FAQ} />
      {/* FIXME: We should really do our apiName validation HERE; is our apiName in our /app/sc/util/constants.js
      file? If not, we shouldn't even start to navigate. */}
      <Route path="/api/:apiName" component={TemplateAPIPage} />
      {/* Fall-back default route */}
      <Route component={NotFound} />
    </Switch>
  </>
);

const RoutesWrapper: React.FunctionComponent = () => <Route component={Routes} />;

export default RoutesWrapper;
