/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key, max-len */
import React, { useEffect } from 'react';
import ContactModal from '@c/Contact/ContactModal';
import { APISteps } from '@c/APISteps/APISteps';
import { NotFound } from '@c/NotFound/NotFound';
import { SanitizeMarkup } from '@u/SanitizeMarkup';
import { API_LIST } from '@u/constants';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

export const TemplateAPIPage = ({
  match: {
    params: { apiName }
  }
}: string | any): JSX.Element | null => {
  let apiData = null;
  useEffect(() => {
    document.title = `${apiName} - Partner Gateway`;
  }, [apiName]);

  if (API_LIST.map(api => api.toLowerCase()).includes(apiName.toLowerCase())) {
    // Dyanmically pull in our API JSON object. This page is inflated from this object.
    // eslint-disable-next-line global-require
    apiData = require(`../../APIs/${apiName.toLowerCase()}.json`);
  } else {
    return <NotFound />;
  }

  const scrollToElement = (targetElement: HTMLElement | null): void => {
    try {
      document.getElementById('api-capabilities')?.focus();
      targetElement?.scrollIntoView({ behavior: 'smooth' });
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(`error: ${error.toString()}`);
    }
  };

  return (
    <>
      <div className="-oneX-container">
        <div className="top-spacer-div -oneX-row -oneX-d-none -oneX-d-md-block" />
        <div className="-oneX-row" style={{ justifyContent: 'space-between' }}>
          <div
            className="-oneX-col-xs-12 -oneX-col-md-6 -oneX-col-lg-6 -oneX-col-xl-6"
            style={{ whiteSpace: 'break-spaces' }}
          >
            <h1 className="trustHeader">{SanitizeMarkup(apiData.headerText)}</h1>
            <div className="paraText">{SanitizeMarkup(apiData.aboveTheFold.leadInMarkup)}</div>
            {apiData?.videoURL?.length > 1 && (
              <LiteYouTubeEmbed
                id={`${apiData.videoURL.substring(apiData.videoURL.lastIndexOf('/') + 1)}`}
                title="State Farm&#174; Renters Insurance API"
                thumbnail={`/assets/${apiName.toLowerCase()}-video-thumbnail.webp`}
                adNetwork={false}
                cookie={false}
                // wrapperClass="embeddedVideo"
                params="start=0&rel=0&html5=1"
                playlist={false}
                webp
              />
            )}

            <div
              className="-oneX-container -oneX-row -oneX-d-flex -oneX-d-md-none"
              style={{ marginTop: '45px', flexDirection: 'column-reverse' }}
            >
              <div className="-oneX-row -oneX-container">
                <button
                  type="button"
                  id="see-api-capabilities-button-1"
                  className="-oneX-btn-secondary cta-btn cta-btn-longtext"
                  onClick={() => scrollToElement(document.getElementById('api-capabilities'))}
                >
                  <span className="button-text">See API Capabilities</span>
                </button>
              </div>
              <div className="spacer-15-px-tall" />
              <div className="-oneX-row -oneX-container">
                <button
                  type="button"
                  id="request-access-button-1"
                  className="-oneX-btn-primary cta-btn"
                  onClick={() => (window as any).oneX.Modal.showModal(window, 'oneX-modal-1')}
                >
                  <span className="button-text">Request Access</span>
                </button>
              </div>
            </div>
          </div>
          <div className="-oneX-col-xs-12 -oneX-col-md-6 -oneX-col-lg-6 -oneX-col-xl-6">
            <img src={`/assets/${apiData.aboveTheFold.pageImage}`} alt="" className="imageContainer" />
          </div>
        </div>
        <div
          className="-oneX-container -oneX-row -oneX-d-md-flex -oneX-d-none button-container-md"
          style={{ marginTop: '45px' }}
        >
          <div>
            <button
              type="button"
              id="see-api-capabilities-button"
              className="-oneX-btn-secondary cta-btn cta-btn-longtext"
              onClick={() => scrollToElement(document.getElementById('api-capabilities'))}
            >
              <span className="button-text">See API Capabilities</span>
            </button>
          </div>
          <div className="spacer-15-px-wide" />
          <div>
            <button
              type="button"
              id="request-access-button"
              className="-oneX-btn-primary cta-btn"
              onClick={() => (window as any).oneX.Modal.showModal(window, 'oneX-modal-1')}
            >
              <span className="button-text">Request Access</span>
            </button>
          </div>
        </div>
        <div className="-oneX-row">
          <div className="-oneX-col-12">
            <h2 className="how-it-works-header">{SanitizeMarkup(apiData.HowItWorks.header)}</h2>
          </div>
        </div>
        {APISteps(apiData)}
        <div>
          <div className="-oneX-row -oneX-col-12">
            <h2 tabIndex={-1} id="api-capabilities" className="api-capabilities-header">
              {SanitizeMarkup(apiData.capabilities.headerText)}
            </h2>
            <div id="api-summary-text" className="api-summary-text">
              {SanitizeMarkup(apiData.capabilities.summaryText)}
            </div>
          </div>
          {apiData.capabilities.bodyList.map(
            (
              capability: {
                pointTitle:
                  | string
                  | boolean
                  | React.ReactChild
                  | React.ReactFragment
                  | React.ReactPortal
                  | null
                  | undefined;
                pointText: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
              },
              idx: number
            ) => (
              <div
                className="-oneX-row -oneX-col-12"
                key={`capability-${idx}${
                  capability.pointTitle
                    ? '-'.concat(
                      (capability.pointTitle as string)
                        .toLowerCase()
                        .substring(0, 15)
                        .replace(' ', '-')
                        .replace('.', '')
                    )
                    : ''
                }`}
              >
                <div>
                  <h3 className="api-capability-header">{capability.pointTitle}</h3>
                  <p className="api-capability-text">{capability.pointText}</p>
                </div>
              </div>
            )
          )}
          <div className="-oneX-d-none -oneX-d-md-flex -oneX-d-lg-flex -oneX-d-xl-flex">
            <div className="-oneX-row -oneX-col-12" style={{ marginTop: '44px', paddingBottom: '8em' }}>
              <button
                id="cta-button-2"
                type="button"
                className="-oneX-btn-primary cta-btn"
                onClick={() => (window as any).oneX.Modal.showModal(window, 'oneX-modal-1')}
              >
                Request Access
              </button>
            </div>
          </div>
          <div
            className="-oneX-container -oneX-col-12 -oneX-d-flex -oneX-d-md-none"
            style={{
              marginTop: '45px',
              marginBottom: '124px',
              flexFlow: 'column',
              paddingLeft: '0px',
              paddingRight: '0px'
            }}
          >
            <div className="-oneX-row -oneX-container">
              <button
                type="button"
                id="request-access-button-3"
                className="-oneX-btn-primary cta-btn"
                onClick={() => (window as any).oneX.Modal.showModal(window, 'oneX-modal-1')}
              >
                <span className="button-text">Request Access</span>
              </button>
            </div>
          </div>
        </div>
        <div className="-oneX-container -oneX-row disclaimer-footer">{SanitizeMarkup(apiData.disclaimerFooter)}</div>
      </div>
      <div style={{ paddingBottom: '1.5em' }} />
      <ContactModal />
    </>
  );
};

export default TemplateAPIPage;
