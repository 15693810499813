/* eslint-disable max-len */
import React, { useEffect } from 'react';

const closeMenu = (): void => {
  // FIXME: This is a hack. We're closing and re-opening the hamburger menu to reset navigation.
  const clickTheMenuButton = async () =>
    (document.getElementsByClassName('-oneX-header-menu-button').item(0) as HTMLElement)?.click();
  clickTheMenuButton().then(() => setTimeout(() => clickTheMenuButton(), 500));
};

const rentersMenuID = 'oneX-submenu-investments';

const hydrateNewSectionInDOM = (elementType: string, elementID: string, mountpointClassName: string): void => {
  const rentersMenuMountPoint = document.getElementsByClassName(mountpointClassName);
  const newElement = document.createElement(elementType);
  newElement.classList.add('-oneX-header-submenu-container');
  newElement.classList.add('-oneX-header-menu');
  newElement.classList.add('-oneX-hidden');
  newElement.id = elementID;
  newElement.setAttribute('role', 'navigation');
  rentersMenuMountPoint[0]?.appendChild(newElement);
};

const Header = (): JSX.Element => {
  useEffect(() => {
    // Hydrate our OneX submenu (desktop view) when the component is mounted.
    // TODO: Come back to this when OneX brings back templating; this is a workaround.
    if (document.getElementById(rentersMenuID) === null) {
      hydrateNewSectionInDOM('section', 'oneX-submenu-investments', '-oneX-header-nav-container');
    }
  });

  return (
    <header className="-oneX noindex" id="oneX-header">
      <div className="-oneX-skipToMain-content">
        <a href="#oneX-main-content" className="-oneX-skipContent-link -oneX-clipped -oneX-link--block">
          Skip to Main Content
        </a>
      </div>
      <nav className="-oneX-header -oneX-container-fluid">
        <section className="-oneX-header-logo-container">
          <a href="https://www.statefarm.com">
            <img
              className="-oneX-header-logo"
              id="oneX-sf-logo"
              src="https://static1.st8fm.com/en_US/dxl-1x/prod/css/images/header/state-farm-logo-2.svg"
              alt="State Farm Insurance and Financial Services"
            />
          </a>
        </section>

        {/* Mobile section */}
        <section className="-oneX-header-mobile-tablet-nav-section">
          <div className="-oneX-row">
            <div className="-oneX-col">
              <button
                type="button"
                aria-expanded="false"
                aria-label="Main Navigation"
                className="-oneX-header-menu-button"
              >
                <div className="-oneX-hamburger__icon">
                  <span />
                  <span />
                  <span />
                </div>
              </button>
            </div>
            <div className="-oneX-col">
              <a href="https://www.statefarm.com" className="-oneX-header-logo-mobile">
                <img
                  id="oneX-sf-logo-mobile"
                  src="https://static1.st8fm.com/en_US/dxl-1x/prod/css/images/header/state-farm-logo-2.svg"
                  alt="State Farm Insurance and Financial Services"
                />
              </a>
            </div>

            <div className="-oneX-header-login-logout-container -oneX-col">
              <button
                type="button"
                className="-oneX-header-nav-login-button"
                data-for="logIn"
                aria-expanded="false"
                aria-label="login"
              >
                <div className="-oneX-login-close__icon -oneX-hidden" />
                <span className="-oneX-header-nav-login-button-text">LOG IN</span>
              </button>
            </div>
          </div>
        </section>

        {/* Desktop section */}
        <section className="-oneX-header-nav-apps-container">
          <div className="-oneX-header-nav-container">
            <div className="-oneX-header-main-nav">
              <ul className="-oneX-header-main-nav-menu">
                <li className="-oneX-header-main-nav-list">
                  <div className="-oneX-header-top-menu-btn-hitbox">
                    <a className="-oneX-header-top-menu-btn" href="/api/renters">
                      <span>RENTERS API</span>
                    </a>
                  </div>
                </li>

                <li className="-oneX-header-main-nav-list">
                  <div className="-oneX-header-top-menu-btn-hitbox">
                    <a className="-oneX-header-top-menu-btn" href="/faq/">
                      <span>FAQ</span>
                    </a>
                  </div>
                </li>

                <li className="-oneX-header-main-nav-list">
                  <div className="-oneX-header-top-menu-btn-hitbox no-top-padding">
                    <a className="-oneX-header-top-menu-btn" href="https://engineering.statefarm.com/" rel="noopener">
                      <span>BLOG</span>
                    </a>
                  </div>
                </li>

                {/* Navbar fix; this will be pulled right. We need this for a call for last-child to set left-margin to auto (pull-right) */}
                <li className="-oneX-header-main-nav-list">
                  <span>&nbsp;</span>
                </li>

                <li id="oneX-redbar" style={{ height: '0px', left: '690.742px' }} />
              </ul>
            </div>
          </div>

          <div
            className="-oneX-header-navmenu-back-container -oneX-hidden"
            data-returnto1="investments"
            data-returnto2="oneX-0-investments-mobile"
          >
            <div className="-oneX-header-navmenu-back" id="navMenuBack">
              <span className="-oneX-back-chevron" />
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                // eslint-disable-next-line no-script-url
                href="javascript:void(0)"
                className="-oneX-link--block"
                aria-label="close header menu"
                onClick={() => closeMenu()}
              >
                Back
              </a>
              <span className="-oneX-down-chevron -oneX-hidden" />
            </div>
          </div>
        </section>
      </nav>
    </header>
  );
};

export default Header;
