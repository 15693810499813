/* eslint-disable */
/* eslint-disable no-console */
/* eslint-disable indent */
import React from 'react';

interface ToastProps {
  message?: string
  iconType?: string
}

const Toast = (props: ToastProps) => (
  <div id="sendmail-toast" className="-oneX-toast-container -oneX-container -oneX-hidden">
    <div className="-oneX-row">
      <div className="-oneX-col">
        <div className="-oneX-toast-box">
          <section className="-oneX-icon-container">
            <span className="-oneX-icon" data-icon={props.iconType} />
              {/* {' '}
            </span> */}
          </section>
          <section tabIndex={-1} className="-oneX-toast__text">
            {props.message}
          </section>
        </div>
      </div>
    </div>
  </div>
);

export default Toast;
