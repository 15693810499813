/* eslint-disable react/prefer-stateless-function */
// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { navigateToRoute } from '@u/network';
import APIStep from '../APISteps/APISteps';

class Home extends Component {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render(): any {
    return (
      <>
        <div className="-oneX-container">
          <div className="top-spacer-div -oneX-row -oneX-d-none -oneX-d-md-block" />
          <div className="-oneX-row" style={{ justifyContent: 'space-between', marginBottom: '50px' }}>
            <div className="-oneX-col-xs-12 -oneX-col-md-6 -oneX-col-lg-7 -oneX-col-xl-7">
              <h1 className="trustHeader">Trust State Farm APIs</h1>
              <div className="paraText">
                <p>
                  For over 100 years, customers have trusted State Farm to protect what&apos;s most important to them.
                </p>
                <p>Let us help connect your customers to the right insurance products using our API technology.</p>
                <p>
                  Our APIs work with your digital platforms for quick and easy customer access &mdash; all from a single
                  application.
                </p>
              </div>
              <div
                className="-oneX-container -oneX-col-12 -oneX-d-flex -oneX-d-md-none"
                style={{
                  marginTop: '45px',
                  marginBottom: '40px',
                  flexFlow: 'column',
                  paddingLeft: '0px',
                  paddingRight: '0px'
                }}
              >
                <div className="-oneX-row -oneX-container">
                  <button
                    type="button"
                    id="explore-our-apis-button"
                    className="-oneX-btn-primary cta-btn"
                    onClick={() => navigateToRoute('/api/renters')}
                  >
                    <span className="button-text">Explore our APIs</span>
                  </button>
                </div>
              </div>
              <div className="-oneX-d-none -oneX-d-md-flex -oneX-d-lg-flex -oneX-d-xl-flex">
                <div className="-oneX-row -oneX-col-12">
                  <button
                    type="button"
                    id="explore-our-apis-button-md"
                    className="-oneX-btn-primary cta-btn"
                    onClick={() => navigateToRoute('/api/renters')}
                  >
                    <span className="button-text">Explore our APIs</span>
                  </button>
                </div>
              </div>
            </div>
            {/* eslint-disable-next-line max-len */}
            <div
              className="-oneX-col-xs-12 -oneX-col-md-6 -oneX-col-lg-5 -oneX-col-xl-5 hero-image-container"
              style={{ overflowX: 'visible' }}
            >
              <img src="/assets/hero-api_03.png" alt="" className="imageContainerHome" />
            </div>
          </div>
          <div className="-oneX-row">
            {/* Show except under XL */}
            <div className="-oneX-no-container -oneX-d-xl-none">
              <div className="-oneX-row full-width-box">
                <div className="-oneX-col-12 khaki-background">
                  <div className="-oneX-offset-md-1 -oneX-offset-lg-1 -oneX-offset-xl-1 pad-api-capability-list">
                    <h2 id="what-can-our-apis-do-header">What can our APIs do for you?</h2>
                    <ul className="what-can-our-apis-do-capability-list">
                      <li>
                        <span>Generate quick quotes and estimates for your customers with an address or ZIP Code.</span>
                      </li>
                      <li>
                        <span>Verify policy information for immediate coverage validation.</span>
                      </li>
                      <li>
                        <span>Provide customers with an easy way to get insurance coverage.</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* XL view */}
            <div className="-oneX-container -oneX-d-none -oneX-d-xl-block">
              <div className="-oneX-row">
                <div className="-oneX-col-12 khaki-background">
                  <div className="pad-api-capability-list">
                    <h2 id="what-can-our-apis-do-header-XL">What can our APIs do for you?</h2>
                    <ul className="what-can-our-apis-do-capability-list">
                      <li>
                        <span>Generate quick quotes and estimates for your customers with an address or ZIP Code.</span>
                      </li>
                      <li>
                        <span>Verify policy information for immediate coverage validation.</span>
                      </li>
                      <li>
                        <span>Provide customers with an easy way to get insurance coverage.</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="-oneX-container">
            <div className="-oneX-row">
              {/* eslint-disable-next-line max-len */}
              <h2 className="got-you-covered-header">We&apos;ve got you covered</h2>
            </div>
            <div className="-oneX-row">
              <div className="api-steps-container-no-margin-or-padding -oneX-container">
                <div className="-oneX-row" style={{ justifyContent: 'space-between' }}>
                  <APIStep
                    maxWidth="50%"
                    pictogram="apis"
                    imageSize={8}
                    header="What are APIs?"
                    // eslint-disable-next-line max-len
                    description="APIs allow customers on your digital platforms to quickly and easily get information."
                  />
                  <APIStep
                    maxWidth="50%"
                    pictogram="api-examples"
                    imageSize={8}
                    colSize={6}
                    header="Everyday Examples of APIs"
                    // eslint-disable-next-line max-len
                    description="You use APIs nearly every time you request information about a product or service online."
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="-oneX-container -oneX-col-12 -oneX-d-flex -oneX-d-md-none"
            style={{
              marginTop: '45px',
              marginBottom: '40px',
              flexFlow: 'column',
              paddingLeft: '0px',
              paddingRight: '0px'
            }}
          >
            <div className="-oneX-row -oneX-container">
              <button
                type="button"
                id="explore-our-apis-button-bottom"
                className="-oneX-btn-primary cta-btn"
                onClick={() => navigateToRoute('/api/renters')}
              >
                <span className="button-text">Explore our APIs</span>
              </button>
            </div>
          </div>
          <div className="-oneX-d-none -oneX-d-md-flex -oneX-d-lg-flex -oneX-d-xl-flex">
            <div className="-oneX-row -oneX-col-12" style={{ marginTop: '44px', paddingBottom: '68px' }}>
              <button
                type="button"
                id="explore-our-apis-button-bottom-md"
                className="-oneX-btn-primary cta-btn"
                onClick={() => navigateToRoute('/api/renters')}
              >
                <span className="button-text">Explore our APIs</span>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Home;
