/* eslint-disable react/jsx-key, react/destructuring-assignment, max-len */
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

export interface APIStepInterface {
  onexIcon?: string;
  imageSize?: number;
  pictogram?: string;
  stepNumber?: number;
  header?: string;
  description: string;
  maxWidth?: string;
  colSize?: number;
}

const iconOrPictogram = (props: APIStepInterface): JSX.Element =>
  props.onexIcon === undefined && props.pictogram
    ? (
        <>
          <div className={`-oneX-col-12 -oneX-col-lg-${props.colSize || 5} api-step`}>
            <div className="-oneX-row api-step-row">
              <div className={`-oneX-pictogram--size${props.imageSize || 6}`} data-pictogram={props.pictogram} />
              <div className="-oneX-col api-step-text-container">
                {props.header && (
                  <h3 className="api-steps-header" style={{ marginTop: '5px' }}>
                    {props.header}
                  </h3>
                )}
                <p style={{ marginTop: '0px' }}>
                  {props.stepNumber && `${props.stepNumber}. `}
                  {(props.description && ReactHtmlParser(props.description)) || 'TODO'}
                </p>
              </div>
            </div>
          </div>
        </>
      )
    : (
        <>
          <div className="-oneX-col-xs-12 -oneX-col-md-12 -oneX-col-lg-4">
            <div className="-oneX-row">
              <div className="-oneX-icon-container" style={{ width: '32px' }}>
                <div className="-oneX-icon" data-icon={`${props.onexIcon || 'star'}_${props.imageSize || '24'}`} />
              </div>
              <div className="-oneX-col">
                {props.header && <h3 style={{ marginTop: '5px' }}>{props.header}</h3>}
                <p className="how-api-works-description-text" style={{ marginTop: '0px' }}>
                  {props.stepNumber && `${props.stepNumber}. `}
                  {(props.description && ReactHtmlParser(props.description)) || 'TODO'}
                </p>
              </div>
            </div>
          </div>
        </>
      );

export const APIStep = (props: APIStepInterface): JSX.Element => iconOrPictogram(props);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const APISteps = (props: any): JSX.Element => (
  <>
    <div className="-oneX-row" style={{ marginBottom: '2em', marginLeft: '0px', justifyContent: 'space-between' }}>
      {props.HowItWorks.bullets.map(
        (bullet: { onexIcon: string | undefined; header: string | undefined; description: string }) => (
          <APIStep
            onexIcon={bullet.onexIcon}
            header={bullet.header}
            description={bullet.description}
            maxWidth="33%"
            key={`APIStep-${bullet.description.substring(0, 15).replace(' ', '-').replace('.', '')}`}
          />
        )
      )}
    </div>
  </>
);

export default APIStep;
