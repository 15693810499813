/* eslint-disable max-len */
import React, { useEffect } from 'react';

export const FAQ = (): JSX.Element => {
  useEffect(() => {
    document.title = 'FAQ';
  }, []);
  return (
    <>
      <div className="-oneX-container">
        <div className="top-spacer-div -oneX-row -oneX-d-none -oneX-d-md-block" />
        <div className="-oneX-row" style={{ justifyContent: 'space-between' }}>
          <div className="-oneX-col-xs-12 -oneX-col-md-6 -oneX-col-lg-7 -oneX-col-xl-7">
            <h1 className="trustHeader">Frequently Asked Questions</h1>
            <div className="paraText">
              <p>
                API stands for
                <em> Application Programming Interface</em>. APIs allow programs to easily share, edit, and delete data
                over the Internet. APIs can provide live data from canonical systems, giving you access to data from the
                source. Software systems integrated with each other through APIs have improved responsiveness and
                accuracy over systems relying on batched reports or other third-party estimates.
              </p>
            </div>
          </div>
          <div className="-oneX-col-xs-12 -oneX-col-md-6 -oneX-col-lg-5 -oneX-col-xl-5">
            <img src="/assets/hero-api_03.png" alt="" className="imageContainer" />
          </div>
        </div>
        <div className="-oneX-row" style={{ marginTop: '0px' }}>
          <div className="-oneX-col-12">
            <span className="faq-header">How can State Farm APIs help&#63;</span>
            <p className="paraText">
              Integrating with State Farm&apos;s APIs can give your application access to data from one of the largest
              insurers in the United States. Today, your business may rely on manually-transferred data from periodic
              reports. This periodic data ends up being an estimate because key data points, such as prices and fees,
              will change. Integrating with our APIs can help remove the reliance on these slow, error-prone, manual
              processes &mdash; replacing them with immediate access to the accurate data your company needs.
            </p>
          </div>
        </div>
        <div className="-oneX-row">
          <div className="-oneX-col-12">
            <span className="faq-header">How to request API access&#63;</span>
            <p className="paraText">
              Currently, you can start by clicking on our Explore Our APIs tab, then select the API that you would like
              to get access to by clicking the Request Access button. Finally, complete the form with your contact
              information and we will reach out to you as soon as possible.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
