import React from 'react';
import { navigateToRoute } from '@u/network';

export const NotFound = (): JSX.Element => (
  <>
    {/* eslint-disable-next-line max-len, object-curly-newline */}
    <div
      className="-oneX-row"
      style={{
        background: 'url(/assets/errorHero-1600.jpg) no-repeat 50%',
        minHeight: '380px',
        backgroundSize: 'cover',
        alignItems: 'center'
      }}
    >
      <div className="-oneX-container">
        <div className="-oneX-row">
          <h1 aria-label="Oops... didn't see that coming." style={{ color: 'white' }}>
            Oops...
            <br />
            didn&apos;t see that coming.
          </h1>
        </div>
        <div className="-oneX-row">
          <button
            type="button"
            className="-oneX-btn-primary -oneX-col-xs-12 -oneX-col-md-4 -oneX-col-lg-3"
            onClick={() => navigateToRoute('/')}
          >
            Return Home
          </button>
        </div>
      </div>
    </div>
  </>
);

export default NotFound;
