import React, { Component } from 'react';
import Header from '@c/Navigation/Header';
import Routes from '../Routes';

// eslint-disable-next-line @typescript-eslint/no-var-requires
require('dotenv').config();

// eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
interface AppProps {
  name?: string;
}

interface OneX {
  // eslint-disable-next-line no-unused-vars, no-use-before-define
  addElement(component: React.RefObject<PartnerGatewayWrapper>): void;
}

class PartnerGatewayWrapper extends Component<AppProps> {
  parentComp: React.RefObject<PartnerGatewayWrapper>;

  oneX: OneX;

  constructor(props: AppProps) {
    super(props);
    this.parentComp = React.createRef() as React.RefObject<PartnerGatewayWrapper>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.oneX = (window as any).oneX;
  }

  componentDidMount(): void {
    this.oneX.addElement(this.parentComp);
  }

  render(): JSX.Element {
    return (
      <>
        <Header />
        <div className="-oneX-header-scroll-spacer" />
        <p id="oneX-main-content" className="-oneX-mainContent-starts -oneX-clipped">
          Start Of Main Content
        </p>
        <main id="content">
          <Routes />
        </main>
        {/* <footer className="-oneX-footer" data-type="1x-footer" /> */}
      </>
    );
  }
}

export default PartnerGatewayWrapper;
